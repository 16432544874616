import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { getPermissions } from '../common/utils/permissions-utils';
import ButtonSubscribe from '../trial/button-subscribe';

export const Dashboard = () => {
  const permissions = getPermissions();
  const { currentAccount: { expiresAt, subscriptionStatus } = {} } = permissions;
  const expDate = new Date(expiresAt);

  const isTrial = () => subscriptionStatus === 'TRIAL' || subscriptionStatus === 'TRIAL_EXPIRED';

  const getContent = () => {
    if (isTrial()) {
      return (
        <CardContent>
          {expDate ? (
            <p>
              Seu período de teste termin{subscriptionStatus === 'TRIAL' ? 'a' : 'ou'} em{' '}
              {expDate.toLocaleDateString('pt-BR')}.
            </p>
          ) : (
            <p>Seu período de teste terminou.</p>
          )}
          <div>
            <ButtonSubscribe />
          </div>
        </CardContent>
      );
    }
    if (subscriptionStatus === 'UNPAID') {
      return (
        <CardContent>
          <p>Não identificamos o pagamento da sua assinatura.</p>
          <div>
            <ButtonSubscribe />
          </div>
        </CardContent>
      );
    }
    return <CardContent>Acesse nossa <a href='https://ajuda.mettzer.com/support/solutions/folders/47000768495'>central de ajuda</a> caso tenha dúvidas em relação ao uso dos módulos de busca de oportunidades de fomento e gestão de projetos.</CardContent>;
  };

  return (
    <Card style={{ margin: '2em' }}>
      <CardHeader title="Bem-vindo ao Mettzer!" />
      {getContent()}
    </Card>
  );
};

export default Dashboard;
